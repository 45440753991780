import { gql } from "@apollo/client";

export const CREATE_USER_BINARY_ROUTINE = gql`
  mutation CreateBinaryRoutineWithArgs(
    $date: Date!
    $category: String!
    $completed: Boolean!
    $userId: ID!
  ) {
    createBinaryRoutine(
      data: {
        date: $date
        category: $category
        completed: $completed
        user: { connect: $userId }
      }
    ) {
      _id
      date
      category
      completed
    }
  }
`;
export const CREATE_USER_ROUTINE = gql`
  mutation CreateRoutineWithArgs(
    $date: Date!
    $category: String!
    $minutes: Int!
    $userId: ID!
  ) {
    createRoutine(
      data: {
        date: $date
        category: $category
        minutes: $minutes
        user: { connect: $userId }
      }
    ) {
      _id
      date
      category
      minutes
    }
  }
`;

// const CALL_LOGIN = gql`
//   mutation CallLogin($email: String!, $password: String!) {
//     login(email: $email, password: $password)
//   }
// `;

// const register = (email, password) => {
//   const query = `
//   mutation CallRegister {
//     register(
//       email: "${email}"
//       password: "${password}"
//     ) {
//       _id
//     }
//   }`;
// };

// async function executeQuery(query) {
//   const FAUNADB_API_KEY = "fnAEA8qJfNAGDX63Zze91r2_HwodE15S2YHX98lU";
//   return fetch("https://graphql.fauna.com/graphql", {
//     method: "POST",
//     headers: {
//       Authorization: "Bearer " + FAUNADB_API_KEY,
//       "Content-Type": "application/json",
//       Accept: "application/json",
//     },
//     body: JSON.stringify({ query }),
//   })
//     .then((el) => el.json())
//     .catch((err) => console.error(`executeQuery error: ${err}`));
// }

// async function login(email, password) {
//   const query = `
//     mutation CallLogin {
//       login(
//         email: "${email}"
//         password: "${password}"
//       )
//     }`;
//   return executeQuery(query).then((result) => {
//     console.log(`result: ${JSON.stringify(result)}`);
//     console.log(`result.data.login: ${result?.data?.login}`);
//     let newSecret = result?.data?.login;
//     // client = new faunadb.Client({
//     //   secret,
//     // });
//     return newSecret;
//   });
// }
