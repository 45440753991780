import { Route, Redirect } from "react-router-dom";
import useLocalStorage from "../../util/useLocalStorage";

function ProtectedRoute({ isAuthenticated, component: Component, ...rest }) {
  const [authToken, setAuthToken] = useLocalStorage("authToken", undefined);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated && !!authToken) {
          // return <Redirect to={{ pathname: "/dashboard" }} />;
          return <Component />;
        } else {
          return <Redirect to={{ pathname: "/login" }} />;
        }
      }}
    />
  );
}

export default ProtectedRoute;
