import { Redirect } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { AuthContext } from "../../util/authContext";
import { format } from "date-fns";
import * as Yup from "yup";
import DatePicker from "react-date-picker";
import { Switch } from "@headlessui/react";
import { GET_USER_ID_BY_EMAIL, GET_USER_ROUTINES } from "../../util/queries";
import {
  CREATE_USER_ROUTINE,
  CREATE_USER_BINARY_ROUTINE,
} from "../../util/mutations";

function DailySubmit() {
  // const today = format(new Date(), "MM-yyyy-dd");

  const [breakfastSwitchValue, setBreakfastSwitchValue] = useState(false);
  const [lunchSwitchValue, setLunchSwitchValue] = useState(false);
  const [dinnerSwitchValue, setDinnerSwitchValue] = useState(false);
  const [datePicker, setDatePicker] = useState(new Date());
  const { auth, setAuth } = useContext(AuthContext);
  const [formValues, setFormValues] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const { data: idData, loading: idLoading, error: idError } = useQuery(
    GET_USER_ID_BY_EMAIL,
    {
      variables: {
        email: auth.email,
      },
    }
  );

  const [createRoutine, {}] = useMutation(CREATE_USER_ROUTINE, {
    refetchQueries: [{ query: GET_USER_ROUTINES }],
  });
  const [createBinaryRoutine, {}] = useMutation(CREATE_USER_BINARY_ROUTINE);

  function createRoutineFactory(arrFormValues, userId) {
    // const date = format(new Date(), "yyyy-MM-dd");
    const date = format(datePicker, "yyyy-MM-dd");
    for (const [key, minutes] of Object.entries(arrFormValues)) {
      let category = key.substr(0, key.indexOf("M")).toUpperCase();
      let routineVars = {
        variables: {
          date,
          category,
          minutes: parseInt(minutes, 10) || 0,
          userId,
        },
      };
      createRoutine(routineVars);
    }
    const cookedMeals = {
      breakfast: breakfastSwitchValue,
      lunch: lunchSwitchValue,
      dinner: dinnerSwitchValue,
    };
    for (const [meal, cooked] of Object.entries(cookedMeals)) {
      let binaryRoutineVars = {
        variables: {
          date,
          category: `COOKED_${meal}`,
          completed: cooked,
          userId,
        },
      };
      createBinaryRoutine(binaryRoutineVars);
    }
  }
  useEffect(() => {
    if (formSubmitted) {
      try {
        if (!idLoading) {
          let userId = idData.userByEmail.data[0]._id;
          createRoutineFactory(formValues, userId);
          setAuth((prevAuth) => ({
            ...prevAuth,
            userId,
          }));
          console.log(`formValues: ${JSON.stringify(formValues, null, 2)}`);
        } else {
          idError
            ? console.error(
                `Issue with userId after form submitted; ${idError}`
              )
            : console.log(`Still loading userId; ${idLoading}`);
        }
      } catch (error) {
        console.error(`Daily checkin failed. ${error}`);
      }
    }
  }, [formValues, formSubmitted]);

  //TODO: Is there a better way to instantiate this?
  const DailyCheckSchema = Yup.object().shape({
    liftMinutes: Yup.number()
      .integer()
      .max(99999, "Lift minutes must be 5 digits or fewer"),
    cycleMinutes: Yup.number()
      .integer()
      .max(99999, "Cycle minutes must be 5 digits or fewer"),
    learnMinutes: Yup.number()
      .integer()
      .max(99999, "Learn minutes must be 5 digits or fewer"),
    readMinutes: Yup.number()
      .integer()
      .max(99999, "Read minutes must be 5 digits or fewer"),
  });

  return (
    <Formik
      className="space-y-8 divide-y divide-gray-200"
      initialValues={{
        liftMinutes: "",
        cycleMinutes: "",
        learnMinutes: "",
        readMinutes: "",
      }}
      validationSchema={DailyCheckSchema}
      onSubmit={async (values, { setStatus, resetForm }) => {
        setFormValues(values);
        setFormSubmitted(true);
        setStatus("submitted");
        resetForm();
      }}
    >
      {({ status }) => {
        return (
          <Form className="space-y-8 divide-y divide-gray-200">
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Daily Score
                  </h3>
                  <div>
                    {/* <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      {today}
                    </p> */}
                    <DatePicker onChange={setDatePicker} value={datePicker} />
                  </div>
                </div>
                <div className="space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="liftMinutes"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Lift
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <Field
                        type="text"
                        name="liftMinutes"
                        id="liftMinutes"
                        placeholder="minutes"
                        className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="cycleMinutes"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Cycle
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <Field
                        type="text"
                        name="cycleMinutes"
                        id="cycleMinutes"
                        placeholder="minutes"
                        className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="learnMinutes"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Learn
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <Field
                        type="text"
                        name="learnMinutes"
                        id="learnMinutes"
                        placeholder="minutes"
                        className="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="readMinutes"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Read
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <Field
                        type="text"
                        id="readMinutes"
                        name="readMinutes"
                        placeholder="minutes"
                        className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div>
                    <Switch.Group
                      as="div"
                      className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
                      // className="flex items-center space-x-4"
                    >
                      <Switch.Label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 sm:col-span-1">
                        🌅 &nbsp; Cooked breakfast
                      </Switch.Label>

                      <div className="mt-1 sm:mt-0 sm:col-span-2 ">
                        <Switch
                          as="button"
                          checked={breakfastSwitchValue}
                          onChange={setBreakfastSwitchValue}
                          className={`${
                            breakfastSwitchValue
                              ? "bg-indigo-600"
                              : "bg-gray-200"
                          } relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:shadow-outline`}
                        >
                          {({ checked }) => (
                            <>
                              <span
                                className={`${
                                  checked ? "translate-x-5" : "translate-x-0"
                                } block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full`}
                              />
                            </>
                          )}
                        </Switch>
                      </div>
                    </Switch.Group>
                  </div>
                  <div>
                    <Switch.Group
                      as="div"
                      className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
                    >
                      <Switch.Label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        ☀️ &nbsp; Cooked lunch
                      </Switch.Label>

                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <Switch
                          as="button"
                          checked={lunchSwitchValue}
                          onChange={setLunchSwitchValue}
                          className={`${
                            lunchSwitchValue ? "bg-indigo-600" : "bg-gray-200"
                          } relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:shadow-outline`}
                        >
                          {({ checked }) => (
                            <>
                              <span
                                className={`${
                                  checked ? "translate-x-5" : "translate-x-0"
                                } inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full`}
                              />
                            </>
                          )}
                        </Switch>
                      </div>
                    </Switch.Group>
                  </div>
                  <div>
                    <Switch.Group
                      as="div"
                      className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
                    >
                      <Switch.Label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        🌇 &nbsp; Cooked dinner
                      </Switch.Label>

                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <Switch
                          as="button"
                          checked={dinnerSwitchValue}
                          onChange={setDinnerSwitchValue}
                          className={`${
                            dinnerSwitchValue ? "bg-indigo-600" : "bg-gray-200"
                          } relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:shadow-outline`}
                        >
                          {({ checked }) => (
                            <>
                              <span
                                className={`${
                                  checked ? "translate-x-5" : "translate-x-0"
                                } inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full`}
                              />
                            </>
                          )}
                        </Switch>
                      </div>
                    </Switch.Group>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {/* This empty div serves as a spacer / HR substitute between form and error messages */}
            </div>
            <ErrorMessage
              name="liftMinutes"
              component="h2"
              className="text-red-700"
            />

            <ErrorMessage
              name="cycleMinutes"
              component="h2"
              className="text-red-700"
            />
            <ErrorMessage
              name="learnMinutes"
              component="h2"
              className="text-red-700"
            />
            <ErrorMessage
              name="readMinutes"
              component="h2"
              className="text-red-700"
            />

            {status == "submitted" ? (
              <>
                <h1 className="text-green-700">Success</h1>
                <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { dailyStatus: "submitted", formValues },
                  }}
                />
              </>
            ) : null}
            <div className="pt-5">
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Save
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
export default DailySubmit;
