import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { AuthContext } from "../../util/authContext";
import { GET_USER_ROUTINES } from "../../util/queries";

function Dashboard() {
  const { auth } = useContext(AuthContext);
  const [sortedData, setSortedData] = useState();
  const location = useLocation();
  const { data, loading, error } = useQuery(GET_USER_ROUTINES, {
    variables: { email: auth.email },
  });

  function sortDescending(dataToSort) {
    return dataToSort
      .sort(({ date: a }, { date: b }) => Date.parse(a) - Date.parse(b))
      .reverse();
  }

  useEffect(() => {
    if (!sortedData && data) {
      let routines = [...data.userByEmail.data[0].routines.data];
      setSortedData(sortDescending(routines));
    }
  }, [data, location?.state?.dailyStatus]);

  return (
    <div>
      {error && <h1>{`You broke it! ${error.message}`}</h1>}
      {loading ? (
        <h1>Loading...</h1>
      ) : (
        <div>
          {location?.state?.dailyStatus == "submitted" ? (
            <h2 className="text-green-700 text-xl">
              Daily check-in submitted!
            </h2>
          ) : null}

          {sortedData ? <DashboardTable data={sortedData} /> : null}
        </div>
      )}
    </div>
  );
}

function DashboardTable(props) {
  const { auth } = useContext(AuthContext);
  return (
    <div className="flex flex-col py-3">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Category
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Minutes
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    User
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.data.map((row, i) => {
                  return (
                    <tr
                      className={i % 2 == 0 ? "bg-white" : "bg-gray-50"}
                      key={row._id}
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {row.date}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {row.category}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {row.minutes}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {auth.email}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
