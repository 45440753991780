import { useState } from "react";
import { format } from "date-fns";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";

import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import { useLocation } from "react-router";

// import useLocalStorage from "../../util/useLocalStorage";
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import DailySubmit from "../DailySubmit/DailySubmit";
import Home from "../Home/Home";
import Dashboard from "../Dashboard/Dashboard";
import Login from "../Login/Login";
import TestUI from "../TestUI/TestUI";
import "./App.css";

import { AuthContext } from "../../util/authContext";

function App() {
  const [auth, setAuth] = useState("");
  // const [authToken, setAuthToken] = useLocalStorage("authToken", undefined);
  const [isOpen, setIsOpen] = useState(false);
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    uri: "https://graphql.fauna.com/graphql",
    headers: {
      // authorization: `Bearer ${localStorage.authToken}`,
      authorization: `Bearer ${auth.authToken}`,
    },
  });

  const TitleText = () => {
    let { pathname } = useLocation();
    if (pathname === "/dashboard") {
      var title = "Dashboard";
    } else {
      title = pathname.substr(1, pathname.length);
    }
    return (
      <h1 className="text-3xl font-bold text-white">{title.toUpperCase()}</h1>
    );
  };

  const today = format(new Date(), "MM/dd/yyyy");
  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      <ApolloProvider client={client}>
        <Router>
          <div className="min-h-screen bg-gray-100">
            <div className="pb-32 bg-indigo-600">
              <nav className="bg-indigo-600 border-b border-indigo-300 border-opacity-25 lg:border-none">
                <div className="px-2 mx-auto max-w-7xl sm:px-4 lg:px-8">
                  <div className="relative flex items-center justify-between h-16 lg:border-b lg:border-indigo-400 lg:border-opacity-25">
                    <div className="flex items-center px-2 lg:px-0">
                      <div className="flex-shrink-0">
                        <NavLink to={{ pathname: "/home" }}>
                          <img
                            className="block w-8 h-8"
                            src="https://tailwindui.com/img/logos/workflow-mark-indigo-300.svg"
                            alt="Workflow"
                          />
                        </NavLink>
                      </div>
                      <div className="hidden lg:block lg:ml-10">
                        <div className="flex space-x-4">
                          <NavLink
                            exact={true}
                            activeClassName="bg-indigo-700"
                            to="/dashboard"
                            className="px-3 py-2 text-sm font-medium text-white rounded-md hover:bg-indigo-500 hover:bg-opacity-75 "
                          >
                            Dashboard
                          </NavLink>
                          <NavLink
                            to="/daily"
                            activeClassName="bg-indigo-700"
                            className="px-3 py-2 text-sm font-medium text-white hover:bg-indigo-500 hover:bg-opacity-75 rounded-md"
                          >
                            Daily Check-In
                          </NavLink>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-center flex-1 px-2 lg:ml-6 lg:justify-end"></div>
                    <div className="flex lg:hidden">
                      {/* <!-- Mobile menu button --> */}
                      <button
                        className="inline-flex items-center justify-center p-2 text-indigo-200 bg-indigo-600 rounded-md hover:text-white hover:bg-indigo-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white"
                        onClick={() => setIsOpen(!isOpen)}
                        aria-expanded="false"
                      >
                        <span className="sr-only">Open main menu</span>
                        {/* <!--
                      Heroicon name: menu
                      Menu open: "hidden", Menu closed: "block"
                    --> */}
                        <svg
                          className={`${isOpen ? "hidden" : "block"} w-6 h-6`}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 6h16M4 12h16M4 18h16"
                          />
                        </svg>
                        {/* <!--
                        Heroicon name: x
                        Menu open: "block", Menu closed: "hidden"
                        --> */}
                        <svg
                          className={`${isOpen ? "block" : "hidden"} w-6 h-6`}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="hidden lg:block lg:ml-4">
                      <div className="flex items-center">
                        <p className="px-3 py-2 text-sm font-medium text-white">
                          {today}
                        </p>
                        <NavLink
                          to="/home"
                          className="flex-shrink-0 p-1 text-indigo-200 bg-indigo-600 rounded-full hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white"
                        >
                          <span className="sr-only">View notifications</span>
                          <img
                            className="inline-block h-8 w-8 rounded-full"
                            src="https://cataas.com/cat"
                            alt=""
                          />

                          {/* <img
                          className="inline-block h-8 w-8 rounded-full"
                          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                          alt=""
                        /> */}
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- Mobile menu, toggle classNames based on menu state. Menu open: "block", Menu closed: "hidden" --> */}
                <div className={`${isOpen ? "block" : "hidden"} lg:hidden`}>
                  <div className="px-2 pt-2 pb-3 space-y-1">
                    {/* <!-- Current: "bg-indigo-700 text-white", Default: "text-white hover:bg-indigo-500 hover:bg-opacity-75" --> */}
                    <NavLink
                      to="/dashboard"
                      exact
                      onClick={() => setIsOpen(!isOpen)}
                      activeClassName="bg-indigo-700"
                      className="block px-3 py-2 text-base font-medium text-white hover:bg-indigo-500 hover:bg-opacity-75 rounded-md"
                    >
                      Dashboard
                    </NavLink>
                    <NavLink
                      to="/daily"
                      activeClassName="bg-indigo-700"
                      onClick={() => setIsOpen(!isOpen)}
                      className="block px-3 py-2 text-base font-medium text-white hover:bg-indigo-500 hover:bg-opacity-75 rounded-md"
                    >
                      Daily Check-In
                    </NavLink>
                  </div>
                  <div className="pt-4 pb-3 border-t border-indigo-700">
                    <div className="flex items-center px-5">
                      <NavLink
                        to="/home"
                        activeClassName="bg-indigo-700"
                        onClick={() => setIsOpen(!isOpen)}
                        className="block px-3 py-2 text-base font-medium text-white hover:bg-indigo-500 hover:bg-opacity-75 rounded-md"
                      >
                        {auth.isAuthenticated ? (
                          <span>Home </span>
                        ) : (
                          <span>Login</span>
                        )}
                      </NavLink>
                    </div>
                  </div>
                </div>
              </nav>
              <header className="py-10">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                  <TitleText />
                </div>
              </header>
            </div>

            <main className="-mt-32">
              <div className="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
                <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
                  <div className="min-h-96 min-h-full border-4 border-dashed border-gray-200 rounded-lg p-5">
                    <Switch>
                      <ProtectedRoute
                        path="/dashboard"
                        component={Dashboard}
                        isAuthenticated={auth.isAuthenticated}
                      />
                      <ProtectedRoute
                        path="/daily"
                        component={DailySubmit}
                        isAuthenticated={auth.isAuthenticated}
                      />
                      <ProtectedRoute
                        path="/home"
                        component={Home}
                        isAuthenticated={auth.isAuthenticated}
                      />
                      <ProtectedRoute
                        exact
                        path="/"
                        component={Login}
                        isAuthenticated={auth.isAuthenticated}
                      />
                      <Route path="/login">
                        <Login />
                      </Route>
                      <Route path="/testui">
                        <TestUI />
                      </Route>
                    </Switch>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </Router>
      </ApolloProvider>
    </AuthContext.Provider>
  );
}

export default App;
