import { gql } from "@apollo/client";

export const GET_USER_ROUTINES = gql`
  query GetAllUserRoutines($email: String!) {
    userByEmail(email: $email) {
      data {
        name
        email
        routines {
          data {
            _id
            _ts
            minutes
            date
            category
          }
        }
      }
    }
  }
`;
export const GET_USER_EMAIL_BY_ID = gql`
  query GetUserById($id: ID!) {
    findUserByID(_id: $id) {
      data {
        _id
        email
      }
    }
  }
`;

export const GET_USER_ID_BY_EMAIL = gql`
  query GetUserByEmail($email: String!) {
    userByEmail(email: $email) {
      data {
        _id
      }
    }
  }
`;
