import { Redirect, NavLink } from "react-router-dom";
import { useEffect, useContext, useState } from "react";
import { AuthContext } from "../../util/authContext";
import useLocalStorage from "../../util/useLocalStorage";

function Home() {
  const { auth, setAuth } = useContext(AuthContext);
  const [authToken, setAuthToken] = useLocalStorage("authToken", undefined);

  useEffect(() => {
    console.log(`Home::auth --> ${JSON.stringify(auth, null, 2)}`);
    console.log(`Home::authToken --> ${authToken}`);
    console.log(
      `Home::localStorage.getItem("authToken") --> ${localStorage.getItem(
        "authToken"
      )}`
    );
  }, []);

  // if (auth.isAuthenticated) {
  console.log(`authToken print from Login: ${authToken}`);
  return (
    <div className="min-h-full bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
          alt="Workflow"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Welcome to Opcuity
        </h2>
        <h2 className="text-xl text-center font-medium text-gray-800 ">
          {auth.email}
        </h2>
      </div>
      <div className="space-y-6">
        <div className="relative w-full mb-3">
          <div className="flex items-center justify-between"></div>
          <div className="mt-6 text-center">
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <div className="mt-2">
                  <NavLink
                    className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    to="/dashboard"
                  >
                    Dashboard
                  </NavLink>
                </div>

                <div className="mt-2">
                  <NavLink
                    to="/daily"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Daily Check-In
                  </NavLink>
                </div>
                <div className="relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300"></div>
                  </div>
                  <div className="relative flex justify-center">
                    <span className="px-2 bg-white text-sm text-gray-500">
                      -
                    </span>
                  </div>
                </div>

                <div className="mt-6">
                  <button
                    type="button"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => {
                      setAuthToken();
                      setAuth({
                        ...auth,
                        isAuthenticated: false,
                      });
                    }}
                  >
                    Log out
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  // } else {
  //   return <Redirect to={{ pathname: "/login" }} />;
  // }
}

export default Home;
