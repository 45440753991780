import { Redirect } from "react-router-dom";
import { useEffect, useContext, useState } from "react";
import { AuthContext } from "../../util/authContext";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import useLocalStorage from "../../util/useLocalStorage";

async function executeQuery(query) {
  const FAUNA = "fnAEA8qJfNAGDX63Zze91r2_HwodE15S2YHX98lU";
  return fetch("https://graphql.fauna.com/graphql", {
    method: "POST",
    headers: {
      Authorization: "Bearer " + FAUNA,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ query }),
  })
    .then((el) => el.json())
    .catch((err) => console.error(`executeQuery error: ${err}`));
}

async function faunaLogin(email, password) {
  const query = `
    mutation CallLogin {
      login(
        email: "${email}"
        password: "${password}"
      )
    }`;

  return executeQuery(query).then((result) => {
    let newSecret = result.data.login;
    return newSecret;
  });
}
function Login() {
  const { auth, setAuth } = useContext(AuthContext);
  const [authToken, setAuthToken] = useLocalStorage("authToken", undefined);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginStatus, setLoginStatus] = useState("");

  // useEffect(() => {
  //   console.log(`Login::auth -> ${JSON.stringify(auth, null, 2)}`);
  //   console.log(`Login::email ${email}`);
  //   console.log(`Login::password ${password}`);
  //   console.log(`Login::loginStatus ${loginStatus}`);
  //   console.log(`Login::authToken ${authToken}`);
  //   console.log(
  //     `localStorage.getItem("authToken"): ${localStorage.getItem("authToken")}`
  //   );
  // }, []);

  useEffect(() => {
    if (email && password) {
      faunaLogin(email, password)
        .then((loginSecret) => {
          console.log(`loginSecret inside faunaLogin: ${loginSecret}`);
          setAuthToken(loginSecret);
          setAuth({
            ...auth,
            isAuthenticated: true,
            email,
            authToken: loginSecret,
          });
        })
        .catch((error) => {
          let status = `Login failed: ${error}`;
          console.log(status);
          setLoginStatus(status);
        });
    } else {
      return null;
    }
  }, [email, password]);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid e-mail address")
      .required("Required email"),
    password: Yup.string().required("Required password"),
  });

  return (
    <div className="min-h-full bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
          alt="Workflow"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Sign in to your account
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600 max-w"></p>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <Formik
            className="relative w-full mb-3"
            initialValues={{ email: "", password: "" }}
            validationSchema={LoginSchema}
            onSubmit={(values, { setStatus, resetForm }) => {
              const { email, password } = values;
              setEmail(email);
              setPassword(password);
              resetForm();
              setStatus("submitted");
            }}
          >
            {({ status }) => {
              return (
                <Form className="space-y-6">
                  <div className="relative w-full mb-3">
                    <div>
                      <label
                        className="block text-sm font-medium text-gray-700"
                        htmlFor="email"
                      >
                        Email address
                      </label>

                      <div className="mt-1">
                        <Field
                          id="email"
                          name="email"
                          type="email"
                          placeholder="Email"
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="mt-6">
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Password
                      </label>
                      <div className="mt-1">
                        <Field
                          id="password"
                          name="password"
                          type="password"
                          placeholder="Password"
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          required
                        />
                      </div>
                    </div>
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-700"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-700"
                    />

                    {status === "submitted" &&
                    authToken &&
                    auth.isAuthenticated ? (
                      <>
                        <Redirect
                          to={{
                            pathname: "/dashboard",
                            state: { authTokenLogin: authToken },
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <span className="text-red-700">{loginStatus}</span>
                      </>
                    )}
                  </div>

                  <div className="flex items-center justify-between"></div>
                  <div className="mt-6 text-center">
                    <button
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      type="submit"
                    >
                      Sign in
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Login;
